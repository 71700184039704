import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";

// Import components
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import About from "./components/AboutUS/AboutUs";
import Service from "./components/Services/Services1";
import Home from "./components/Home/Home";
import ContactUs from "./components/ContactUs/ContactUs";
import Blog from "./components/Blog/Blog";
import Product from "./components/Product/Products";
import LogoContact from "./components/Logo_Contact/Logo_contact";
import Realestate from "./components/RealEstate/Realestate";
import ScrollToTop from "./components/ScrollToUp/ScrollToTop";
// const About = () => <div>About Page</div>;
// const Service = () => <div>Service Page</div>;
// const Team = () => <div>Team Page</div>;
// const Category = () => <div>Category Page</div>;

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="main">
        <LogoContact />
        <Navbar />
        <div className="sub-main">
          <div className="body_section">
            <Routes>
              {/* <Route path="/" element={<Layout />}> */}
              <Route path="/" element={<Home />} />
              <Route path="/about-us" element={<About />} />
              <Route path="/service" element={<Service />} />
              <Route path="/ContactUs " element={<ContactUs />} />
              <Route path="/Blog" element={<Blog />} />
              <Route path="/RealEstate" element={<Realestate />} />
              <Route path="/product" element={<Product />} />
              {/* <Route path="/team" element={<Team />}
                <Route path="/category" element={<Category />} /> */}
              {/* </Route> */}
            </Routes>
          </div>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
