import React, { useState } from "react";
import "./Header.css";
// import back_video from "../../Asset/video/back_video.mp4";
import Asian_women from "../../Asset/Image/Asian_women.png";
import xerologo from "../../Asset/Image/Xeroverse logo.png";
import ContactUs from "../ContactUs/ContactUs";

const Header = () => {
  const [showContactForm, setShowContactForm] = useState(false);

  const handleClick = () => {
    console.log("Button clicked!");
    setShowContactForm(true);
  };

  const closeContactForm = () => {
    setShowContactForm(false);
  };

  return (
    <div className="top_ladning">
      <div className="top_main">
        <video className="vid" autoPlay loop muted id="video">
          <source
            src="https://xeroverse-media.s3.ap-south-1.amazonaws.com/back_video.mp4"
            type="video/mp4"
          />
        </video>
      </div>

      <div className="header">
        {/* <Link to="/">
          <img src={xerologo} alt="Xeroverse Logo" className="xero_logo" />
        </Link> */}
        <div>
          {/* <button className="btn" onClick={handleClick}>
            Contact Us
          </button> */}
        </div>
      </div>
      <div className="top_content">
        <div className="content">
          <div className="content_top">
            <p className="words2">LET'S</p>
            <div className="words">
              <span className="w1">CREATE</span>
              <span className="w1">ENVISION</span>
              <span className="w1">INHABIT</span>
            </div>
          </div>
          <div className="content_bottom">
            <p className="two">YOUR DREAM xr</p>
            <div className="cont_full">
              <p className="word1">CONTENT.</p>
            </div>
          </div>
          <button className="btn_img" onClick={handleClick}>
            <div className="inner_button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                <path
                  d="M13.2998 9.64365L9.14688 6.61354C8.99447 6.50265 8.79215 6.48595 8.6251 6.57213C8.45672 6.65763 8.35156 6.83132 8.35156 7.01903V13.0772C8.35156 13.267 8.45672 13.44 8.6251 13.5255C8.69631 13.5615 8.77418 13.5796 8.85271 13.5796C8.9552 13.5796 9.05903 13.5468 9.14688 13.4821L13.2998 10.4546C13.4309 10.3578 13.5075 10.2081 13.5075 10.0491C13.5081 9.88748 13.4296 9.73851 13.2998 9.64365Z"
                  fill="#121212"
                />
                <path
                  d="M10.0003 0C4.47639 0 0 4.49305 0 10.0376C0 15.5801 4.47639 20.0718 10.0003 20.0718C15.5229 20.0718 20 15.5794 20 10.0376C20.0007 4.49305 15.5229 0 10.0003 0ZM10.0003 18.3971C5.40015 18.3971 1.67049 14.6555 1.67049 10.0376C1.67049 5.42159 5.40015 1.67538 10.0003 1.67538C14.5998 1.67538 18.3288 5.42092 18.3288 10.0376C18.3295 14.6555 14.5998 18.3971 10.0003 18.3971Z"
                  fill="#121212"
                />
              </svg>
              <p className="bt">View Demo</p>
            </div>
          </button>
        </div>
        <div className="Asian_img">
          <img src={Asian_women} alt="asian_women"></img>
        </div>
      </div>

      {/* {showContactForm && <ContactUs closeModal={closeContactForm} />} */}
    </div>
  );
};

export default Header;
