// src/components/Products.js
// import React from "react";
import ContactUs from "../ContactUs/ContactUs";
import React, { useState } from "react";
import Product from "./Product";
import archi from "../../Asset/Image/Marine.jpg";
import virtual from "../../Asset/Image/education.jpg";
import retail from "../../Asset/Image/Safety.jpg";
import kids from "../../Asset/Image/fashion.jpg";
import production from "../../Asset/Image/Gaming.jpg";
import medical from "../../Asset/Image/digital-medical.jpg";
import { Link } from "react-router-dom";
import "./Products.css";
const Products = () => {
  const [showContactForm, setShowContactForm] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [activeButton, setActiveButton] = useState("others");

  const toggleContactForm = (product) => {
    setSelectedProduct(product);
    setShowContactForm(!showContactForm);
  };

  const products = [
    {
      title: "Transform Marine Training with VR",
      subtitle: "AR VR in Architecture",
      description:
        "Enhance marine maintenance and safety training with our immersive Virtual Reality (VR) platform. Train your crew in realistic, risk-free environments, practicing essential tasks like equipment maintenance and emergency procedures. Our VR solutions boost safety awareness and skill retention, ensuring your team is always ready for challenges at sea.",
      buttonText: "Contact US",
      imagePath: archi,
    },
    {
      title: "Transform K-12 Learning with VR",
      subtitle: "Virtual Property Tours",
      description:
        "Engage students like never before with our immersive Virtual Reality (VR) platform for K-12 education. VR brings subjects to life, allowing students to explore historical sites, dive into science experiments, and interact with 3D environments. By making learning fun and interactive, VR enhances understanding, boosts retention, and sparks curiosity—creating an exciting, modern approach to education.",
      buttonText: "Contact US",
      imagePath: virtual,
    },
    {
      title: "Improve Safety in Hazardous Fields with VR",
      subtitle: "Enhanced Shopping Experience",
      description:
        "Equip your team for high-risk environments with immersive Virtual Reality (VR) safety training. Our simulations let workers practice handling dangerous scenarios—such as chemical spills or equipment failures—in a safe, controlled setting. Enhance safety awareness, improve response times, and reduce accidents with realistic, risk-free training",
      buttonText: "Contact US",
      imagePath: retail,
    },
    {
      title: "Virtual Fashion Try-On with VR",
      subtitle: "Immersive Learning Experiences",
      description:
        "Transform shopping with Virtual Reality (VR) try-ons, allowing customers to see how garments fit and look in real-time. This immersive experience offers a 360-degree view of clothing, helping customers make confident choices from the comfort of their home while reducing returns and enhancing the shopping journey.",
      buttonText: "Contact US",
      imagePath: kids,
    },
    {
      title: "Dive into the Exciting World of VR Gaming",
      subtitle: "Smart Factory Solutions",
      description:
        "Experience gaming like never before with Virtual Reality (VR). Our platform immerses you in stunning virtual worlds, allowing you to interact and explore in ways traditional gaming can't. Whether you're embarking on thrilling adventures or competing with friends, VR gaming offers an exhilarating experience that puts you at the center of the action.",
      buttonText: "Contact US",
      imagePath: production,
    },
  ];

  return (
    <div className="products">
      <div className="realestate-buttons">
        <Link to="/RealEstate">
          <button
            className={`real1-button ${
              activeButton === "realestate" ? "active" : ""
            }`}
            onClick={() => setActiveButton("realestate")}
          >
            Real Estate
          </button>
        </Link>

        <Link to="/product">
          <button
            className={`real2-button ${
              activeButton === "others" ? "active" : ""
            }`}
            onClick={() => setActiveButton("others")}
          >
            Others
          </button>
        </Link>
      </div>

      {products.map((product, index) => (
        <Product
          key={index}
          index={index}
          title={product.title}
          subtitle={product.subtitle}
          description={product.description}
          buttonText={product.buttonText}
          imagePath={product.imagePath}
          onButtonClick={() => toggleContactForm(product)}
        />
      ))}
      {showContactForm && (
        <div className="contact-form-overlay">
          <div className="contact-form-container">
            <ContactUs
              product={selectedProduct}
              onClose={() => setShowContactForm(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Products;
