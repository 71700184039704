import Header from "../Header/Header";
import Mid_Sec from "../Mid_Sec/category";
import Mid_box from "../Mid_box/Mid_box";
import ToggleContent from "../ToggleContent/ToggleContent";
import FAQItem from "../FAQItem/FAQItem";
// import VideoCarousel2 from "../VideoCarousel/VideoCarousel2";
import LatestNews from "../LatestNews/LatestNews";
import TrustedByBrands from "../../TrustedByBrands/TrustedByBrands";
import TestimonialSlider from "../TestimonialSlider/TestimonialSlider";
import Services2 from "../Services2/Services2";
import Services3 from "../Services3/Services3";
import "./home.css";

function Home() {
  return (
    <div className="body_section">
      <Header />
      <Mid_Sec />
      <ToggleContent />
      <TrustedByBrands />

      <Mid_box />
      <TestimonialSlider />
      <Services2 />
      <Services3 />
      <FAQItem />

      {/* <VideoCarousel2 /> */}
      {/* <LatestNews /> */}
    </div>
    // <div>
    //   <Header />
    //   <Mid_Sec />
    //   <Mid_box />
    //   <ToggleContent />
    //   <FAQItem />
    //   <VideoCarousel2 />
    //   <LatestNews />
    // </div>
  );
}
export default Home;
